import * as THREE from 'three'
import Experience from '../Experience.js'
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper.js";

export default class HangLight
{
    constructor(position,lightPosition,modelName,power)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug


        // Resource
        this.resource = this.resources.items[modelName]

        this.setModel(position,lightPosition,power)
        this.setAnimation()
    }

    setModel(position,lightPosition,power)
    {
        this.model = this.resource.scene
        this.model.scale.set(0.15, 0.15, 0.15)
        this.model.position.set(position.x,position.y,position.z)

        this.hangLight = new THREE.PointLight(0xEFC070, 50, 200, 2);
   
        const sphere = new THREE.SphereGeometry( 0.03, 16, 8 );
        
        this.hangLight.position.set(lightPosition.x,lightPosition.y,lightPosition.z);
        this.hangLight.add(new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xEFC070 } ) ))
        this.hangLight.power = power
        this.scene.add(this.hangLight); 
        this.scene.add(this.model)

    }

    setAnimation()
    {
        this.animation = {}
        
   
    }

}