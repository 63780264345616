import * as THREE from 'three'
import Experience from '../Experience.js'

export default class FrontWall
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug


        // Resource
        this.resource = this.resources.items.frontWallModel

        this.setModel()
    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.position.set(0,-2,-1.25)
        this.scene.add(this.model)

        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                // child.castShadow = true
                child.material = new THREE.MeshStandardMaterial({
                    color: 0x131035,
                })
            }
        })
    }


    
}