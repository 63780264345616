export default [
    {
        name: 'rainCloudTexture',
        type: 'texture',
        path: 'textures/smoke.png'
    },
    {
        name: 'rainCloudAlphaTexture',
        type: 'texture',
        path: 'textures/alphaSmoke.jpg'
    },
    {
        name: 'tableTopTexture',
        type: 'texture',
        path: 'textures/tableTop/flat.jpg'
    },
    {
        name: 'tableModel',
        type: 'gltfModel',
        path: 'models/table4.glb'
    },
    {
        name: 'frontWallModel',
        type: 'gltfModel',
        path: 'models/frontWall.glb'
    },
    {
        name: 'chairModel',
        type: 'gltfModel',
        path: 'models/chair.glb'
    },
    {
        name: 'windowModel',
        type: 'gltfModel',
        path: 'models/window.glb'
    },
    {
        name: 'ironManModel',
        type: 'gltfModel',
        path: 'models/ironman.glb'
    },
    {
        name: 'plantMModel',
        type: 'gltfModel',
        path: 'models/plant.glb'
    },
    {
        name: 'dropLightModel',
        type: 'gltfModel',
        path: 'models/dropLight.glb'
    },
    {
        name: 'dropLightFront1Model',
        type: 'gltfModel',
        path: 'models/dropLight.glb'
    },
    {
        name: 'dropLightRight1Model',
        type: 'gltfModel',
        path: 'models/dropLight.glb'
    },
    {
        name: 'dropLightRight2Model',
        type: 'gltfModel',
        path: 'models/dropLight.glb'
    },
    {
        name: 'dropLightRight3Model',
        type: 'gltfModel',
        path: 'models/dropLight.glb'
    },
    {
        name: 'dropLightRight4Model',
        type: 'gltfModel',
        path: 'models/dropLight.glb'
    },
    {
        name: 'btechDegreeTexture',
        type: 'texture',
        path: 'textures/btech/btechDegree.jpg'
    },
    {
        name: 'pictureFrameModel',
        type: 'gltfModel',
        path: 'models/pictureFrame1.glb'
    },
    {
        name: 'lampModel',
        type: 'gltfModel',
        path: 'models/lamp1.glb'
    },
    {
        name: 'monitorModel',
        type: 'gltfModel',
        path: 'models/monitor.glb'
    },
    {
        name: 'macbookModel',
        type: 'gltfModel',
        path: 'models/macbook.glb'
    },
    {
        name: 'bookShelfModel',
        type: 'gltfModel',
        path: 'models/bookshelf.glb'
    },
    {
        name: 'couchModel',
        type: 'gltfModel',
        path: 'models/couch1.glb'
    },
    {
        name: 'couchColorTexture',
        type: 'texture',
        path: 'textures/couch/couchColor.jpg'
    },
    {
        name: 'leftCushionColor1Texture',
        type: 'texture',
        path: 'textures/couch/leftCushionColor1.jpg'
    },
    {
        name: 'leftCushionColor2Texture',
        type: 'texture',
        path: 'textures/couch/leftCushionColor2.jpg'
    },
    {
        name: 'rightCushionColorTexture',
        type: 'texture',
        path: 'textures/couch/rightCushionColor.jpg'
    },
    {
        name: 'blanketColorTexture',
        type: 'texture',
        path: 'textures/couch/blanketColor.jpg'
    },
    {
        name: 'hangLight1Model',
        type: 'gltfModel',
        path: 'models/hangLight.glb'
    },
    {
        name: 'hangLight2Model',
        type: 'gltfModel',
        path: 'models/hangLight.glb'
    },
    {
        name: 'hangLight3Model',
        type: 'gltfModel',
        path: 'models/hangLight.glb'
    },
    {
        name: 'hangLight4Model',
        type: 'gltfModel',
        path: 'models/hangLight.glb'
    },
    {
        name: 'doorModel',
        type: 'gltfModel',
        path: 'models/door.glb'
    },
    {
        name: 'resumeModel',
        type: 'gltfModel',
        path: 'models/resume.glb'
    },
    {
        name: 'rainDropTexture',
        type: 'texture',
        path: 'textures/rainDrop.png'
    },
    {
        name: 'pictureFrame1Model',
        type: 'gltfModel',
        path: 'models/pictureFrame1.glb'
    },
    {
        name: 'switchModel',
        type: 'gltfModel',
        path: 'models/switch.glb'
    },
    {
        name: 'laptopStandModel',
        type: 'gltfModel',
        path: 'models/laptopStand.glb'
    },
    {
        name: 'keyboardModel',
        type: 'gltfModel',
        path: 'models/keyBoard.glb'
    },
    {
        name: 'rajasthanTexture',
        type: 'texture',
        path: 'textures/rajasthan_state_map.png'
    },
    {
        name: 'redHatFont',
        type: 'font',
        path: 'fonts/Red_Hat_Text_Regular.json'
    },
    {
        name: 'tvTexture',
        type: 'texture',
        path: 'textures/tv.jpg'
    },
    {
        name: 'tvModel',
        type: 'gltfModel',
        path: 'models/tv.glb'
    }
]