import * as THREE from "three";
import Experience from "../Experience.js";

export default class Rain {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    this.setGeometry();
    this.setTextures();
    this.setMaterial();
    this.setMesh();
    this.thunderWithRain = new Audio("sounds/rainWithThunder.mp3");
    this.thunderWithRain.volume = 0.4;
    this.thunderStrike = new Audio("sounds/thunder.mp3");
    this.clockRain = new THREE.Clock();
    this.oldTimeRain = this.clockRain.getElapsedTime();
    // this.thunderWithRain.play();
    this.clockThunder = new THREE.Clock();
    this.oldTimeThunder = this.clockThunder.getElapsedTime();
    // this.setThunderLight();
    this.thunderFlag = 0;
    const self = this
    // this.experience.camera.controls.addEventListener("lock", function () {
    //   self.thunderWithRain.play();
    //   self.setThunderLight();
    //   // console.log(self)
    // })
  }

  setGeometry() {
    this.rainCount = 15000;
    this.cloudGeo = new THREE.PlaneBufferGeometry(500, 500);
    this.rainGeo = new THREE.BufferGeometry();

    let rainDrop = new Float32Array(this.rainCount * 4)
    for(let i = 0; i < this.rainCount; i++)
    {
        const i4 = i * 4

        rainDrop[i4    ] = Math.random() * 400 - 200
        rainDrop[i4 + 1] = Math.random() * 500 - 250
        rainDrop[i4 + 2] = Math.random() * 200 - 200
        rainDrop[i4 + 3] = 0
    }

    this.rainGeo.setAttribute('position', new THREE.BufferAttribute(rainDrop, 4))
  }

  setTextures() {
    this.textures = {};

    this.textures.color = this.resources.items.rainCloudTexture;
    this.textures.color.encoding = THREE.sRGBEncoding;
    

    this.textures.alpha = this.resources.items.rainCloudAlphaTexture;

    
  }

  setMaterial() {
    this.cloudMaterial = new THREE.MeshLambertMaterial({
      map: this.textures.color,
      transparent: true,
      side: THREE.DoubleSide,
      //   alphaMap: this.textures.alpha
    });
    this.rainMaterial = new THREE.PointsMaterial({
      color: 0x99EDF3,
      size: 0.5,
      fog: false,
      alphaMap: this.resources.items.rainDropTexture,
      depthWrite : false,
      blending : THREE.AdditiveBlending,
      side: THREE.DoubleSide,
    });
  }

  setMesh() {
    this.cloudParticles = [];
    for (let p = 0; p < 25; p++) {
      let cloud = new THREE.Mesh(this.cloudGeo, this.cloudMaterial);
      cloud.position.set(
        Math.random() * 500 - 400,
        150,
        Math.random() * -500 - 150
      );
      cloud.rotation.x = 1.16;
      cloud.rotation.y = -0.12;
      cloud.rotation.z = Math.random() * 360;
      cloud.material.opacity = 0.6;
      this.cloudParticles.push(cloud);
      this.scene.add(cloud);
    }
    this.rain = new THREE.Points(this.rainGeo, this.rainMaterial);
    this.scene.add(this.rain);
  }

  setThunderLight() {
    this.thunderStruck = new THREE.PointLight(0x555555, 100, 300, 1.7);
    this.thunderStruck.position.set(0, 4, -10);
    this.scene.add(this.thunderStruck);
    this.thunderFlag = 1
    this.oldTimeRain = this.clockRain.getElapsedTime();
  }

  update() {
    this.cloudParticles.forEach((p) => {
      p.rotation.z -= 0.002;
    });

    for(let i = 0; i < this.rainCount; i++){
        const i4 = i * 4
        // let py = this.rainGeo.attributes.position.array[i4 + 1]
        this.rainGeo.attributes.position.array[i4 + 3] -= 0.001 + Math.random() * 0.1;
        this.rainGeo.attributes.position.array[i4 + 1] += this.rainGeo.attributes.position.array[i4 + 3]
        if (this.rainGeo.attributes.position.array[i4 + 1] < -100) {
            this.rainGeo.attributes.position.array[i4 + 1] = 200;
            this.rainGeo.attributes.position.array[i4 + 3] = 0;
        }
    }

    this.rainGeo.attributes.position.needsUpdate = true

    this.rain.rotation.y += 0.002;

    if (this.clockRain) {
      if (this.clockRain.getElapsedTime() - this.oldTimeRain > 55) {
        // thunderWithRain.pause();
        this.thunderWithRain.currentTime = 5;
        this.thunderWithRain.play();
        this.oldTimeRain = this.clockRain.getElapsedTime();
      }
    }

    if (this.thunderStruck) {
      if (this.clockThunder) {
        // console.log("old",this.oldTimeThunder)
        // console.log("elap",this.clockThunder.getElapsedTime())

        if (
          this.clockThunder.getElapsedTime() - this.oldTimeThunder > 19 &&
          this.clockThunder.getElapsedTime() - this.oldTimeThunder <= 23
        ) {
          this.thunderStruck.power = 50 + Math.random() * 15000;
          if (
            this.clockThunder.getElapsedTime() - this.oldTimeThunder > 19 &&
            this.clockThunder.getElapsedTime() - this.oldTimeThunder < 21
          ) {
            if (this.thunderFlag) {
              console.log(
                this.clockThunder.getElapsedTime() - this.oldTimeThunder
              );
              this.thunderStrike.currentTime = 0;
              this.thunderStrike.play();
              this.thunderFlag = 0;
            }
          }
          
        }else if (this.clockThunder.getElapsedTime() - this.oldTimeThunder >= 21) {
          this.oldTimeThunder = this.clockThunder.getElapsedTime() + 5;
          this.thunderStruck.power = 0;
          this.thunderFlag = 1;
        }
      }
    }
  }
}
