import * as THREE from 'three'
import Experience from '../Experience.js'

export default class PlantM
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        // Debug
        

        // Resource
        this.resource = this.resources.items.plantMModel

        this.setModel()
        this.setAnimation()
    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(0.3, 0.3, 0.3)
        this.model.position.set(4.45,-1.7,-0.48)
        // this.model.rotation.set(0,-Math.PI/3,0)
        this.scene.add(this.model)

        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                // child.castShadow = true
                if(child.material.name == 'Material'){
                    child.material = new THREE.MeshPhongMaterial({
                            color: child.material.color,
                        })
                }else if(child.material.name == 'mud'){
                    child.material = new THREE.MeshPhongMaterial({
                            color: child.material.color,
                            side: THREE.DoubleSide
                        })
                }
            }
        })
    }

    setAnimation()
    {
        this.animation = {}
        
        // Mixer
    //     this.animation.mixer = new THREE.AnimationMixer(this.model)
        
    //     // Actions
    //     this.animation.actions = {}
        
    //     this.animation.actions.idle = this.animation.mixer.clipAction(this.resource.animations[0])
    //     this.animation.actions.walking = this.animation.mixer.clipAction(this.resource.animations[1])
    //     this.animation.actions.running = this.animation.mixer.clipAction(this.resource.animations[2])
        
    //     this.animation.actions.current = this.animation.actions.idle
    //     this.animation.actions.current.play()

    //     // Play the action
    //     this.animation.play = (name) =>
    //     {
    //         const newAction = this.animation.actions[name]
    //         const oldAction = this.animation.actions.current

    //         newAction.reset()
    //         newAction.play()
    //         newAction.crossFadeFrom(oldAction, 1)

    //         this.animation.actions.current = newAction
    //     }

    //     // Debug
    //     if(this.debug.active)
    //     {
    //         const debugObject = {
    //             playIdle: () => { this.animation.play('idle') },
    //             playWalking: () => { this.animation.play('walking') },
    //             playRunning: () => { this.animation.play('running') }
    //         }
    //         this.debugFolder.add(debugObject, 'playIdle')
    //         this.debugFolder.add(debugObject, 'playWalking')
    //         this.debugFolder.add(debugObject, 'playRunning')
    //     }
    }

    // update()
    // {
    //     this.animation.mixer.update(this.time.delta * 0.001)
    // }
}