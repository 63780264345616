import * as THREE from 'three'
import Experience from '../Experience.js'

export default class PictureFrame
{
    constructor(position,scale,texture,color,model)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('pictureFrame')
        }

        // Resource
        this.resource = model

        this.setTextures(texture)
        this.setModel(position,scale,color)
        this.setAnimation()
    }

    setTextures(texture)
    {
        this.textures = {}

        this.textures.color = texture
        this.textures.color.encoding = THREE.sRGBEncoding
        this.textures.color.flipY = false

    }

    setModel(position,scale,color)
    {
        this.model = this.resource.scene
        this.model.scale.set(scale.x,scale.y,scale.z)

        this.model.position.set(position.x,position.y,position.z)

        this.model.rotation.y = -Math.PI / 2

        this.scene.add(this.model)

        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.castShadow = true
                if(child.name == "picture"){
                    child.material = new THREE.MeshBasicMaterial({
                        map: this.textures.color,
                        color: 0xffffff,
                        side: THREE.DoubleSide
                    })
                }else if(child.name == "frame"){
                    child.material = new THREE.MeshPhongMaterial({
                        color: color,
                        side: THREE.DoubleSide
                    })
                }else if(child.name == "bufferZone"){
                    child.material = new THREE.MeshStandardMaterial({
                        color: 0x03045e,
                        roughness: 1
                    })
                }
            }
        })
    }

    setAnimation()
    {
        this.animation = {}
    }

   
}