import "./style.css";
import * as THREE from "three";
import { gsap } from "gsap";
import Experience from "./Experience/Experience.js";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { inject } from '@vercel/analytics';
 
inject();

const instructionPanel = document.getElementById("instrucionPanel");
const instructions = document.getElementById("instructions");
const introElement = document.getElementById("startButton");
const loaderTab = document.getElementById("loaderTab");
const loaderText = document.getElementById("loaderText");


function downloadFunc() {
  var anchor = document.createElement("a");
  anchor.setAttribute("href", "./Prasoon_Resume.pdf");
  anchor.setAttribute("download", "");
  document.body.appendChild(anchor);
  anchor.click();
  anchor.parentNode.removeChild(anchor);
}

let allThingsLoaded = 0;

const loadingManager = new THREE.LoadingManager();
let experience = new Experience(
  document.querySelector("canvas.myRoom"),
  loadingManager
);
loadingManager.onStart = () => {
  console.log("loading started");
  instructions.style.color = "antiquewhite";
  loaderText.style.color = "#131035";
};
loadingManager.onLoad = () => {
  window.setTimeout(() => {
    console.log("loading finished");
    allThingsLoaded = 1;
    loaderTab.style.transform = `scaleY(1)`;
    window.setTimeout(() => {
      loaderText.innerText = "Enter Room";
     
      experience.room.rain.setThunderLight()
    }, 700);
  }, 100);
};
loadingManager.onProgress = (itemLink, itemLoaded, itemTotal) => {
  const ratio =
    itemLoaded / itemTotal - 0.1 < 0 ? 0 : itemLoaded / itemTotal - 0.1;
  loaderTab.style.transform = `scaleY(${ratio})`;
};
loadingManager.onError = () => {
  console.log("loading error");
};


const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1);
const overlayMaterial = new THREE.ShaderMaterial({
  transparent: true,
  vertexShader: `
      void main()
      {
          gl_Position = vec4(position, 1.0);
      }
  `,
  fragmentShader: `
      uniform float uAlpha;
      void main()
      {
          gl_FragColor = vec4(0.075,0.063,0.208, uAlpha);
      }
  `,
  uniforms: {
    uAlpha: { value: 1 },
  },
});
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial);
experience.scene.add(overlay);

introElement.addEventListener("click", (event) => {
  event.preventDefault();
  if (allThingsLoaded) {
    console.log("clicked");
    gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 1, value: 0 });
    experience.camera.controls.lock();
  }
});
introElement.addEventListener("touchstart", (event) => {
  event.preventDefault();
  if (allThingsLoaded) {
    console.log("clicked");
    gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 1, value: 0 });
    instructionPanel.style.display = "none";
    experience.room.rain.thunderWithRain.play()
  }
});
experience.camera.controls.addEventListener("lock", function () {
  instructionPanel.style.display = "none";
  experience.room.rain.thunderWithRain.play()
});
experience.camera.controls.addEventListener("unlock", function () {
  loaderText.innerText = "Resume";
  instructionPanel.style.display = "block";
});


window.addEventListener("keydown", (event) => {
  if (event.code == "KeyR") {
    downloadFunc();
  }
});
const loader = new FontLoader();

// Skills
loader.load("fonts/Red_Hat_Text_Regular.json", function (font) {

  // const materialTextName = new THREE.MeshStandardMaterial({ color: 0x959595 });
  // const  geometryTextName = new TextGeometry("Prasoon Mahawar", {
  //   font: font,
  //   size: 0.1,
  //   height: 0.0001,
  //   curveSegments: 5,
  // });

  // // geometryTextName.center();
  // const skillsTextName = new THREE.Mesh( materialTextName, geometryTextName);
  // skillsTextName.position.x = 5;
  // skillsTextName.position.y = 1;
  // skillsTextName.position.z = 0.3;
  // skillsTextName.rotation.y = -Math.PI / 2;

  // experience.scene.add(skillsTextName);


  //Work Experice
  const materialTextWorkExp = new THREE.MeshStandardMaterial({ color: 0x959595 });
  const  geometryTextWorkExp = new TextGeometry("Prasoon Mahawar", {
    font: font,
    size: 0.14,
    height: 0.0001,
    curveSegments: 5,
  });

   geometryTextWorkExp.center();
  const skillsTextWorkExp = new THREE.Mesh( geometryTextWorkExp, materialTextWorkExp);
  skillsTextWorkExp.position.x = 5;
  skillsTextWorkExp.position.y = 1;
  skillsTextWorkExp.position.z = 0.5;
  skillsTextWorkExp.rotation.y = -Math.PI / 2;

  const  geometryTextASMDesig = new TextGeometry(`Software Engineer`, {
    font: font,
    size: 0.1,
    height: 0.0001,
    curveSegments: 5,
  });

  // geometryTextASMDesig.center();
  const skillsTextASMDesig = new THREE.Mesh( geometryTextASMDesig, materialTextWorkExp);
  skillsTextASMDesig.position.x = 5;
  skillsTextASMDesig.position.y = 0.7;
  skillsTextASMDesig.position.z = -0.25;
  skillsTextASMDesig.rotation.y = -Math.PI / 2;

  const  geometryTextF1 = new TextGeometry(`F1 Enthusiast`, {
    font: font,
    size: 0.1,
    height: 0.0001,
    curveSegments: 5,
  });

  // geometryTextASMDesig.center();
  const skillsTextF1 = new THREE.Mesh( geometryTextF1, materialTextWorkExp);
  skillsTextF1.position.x = 5;
  skillsTextF1.position.y = 0.5;
  skillsTextF1.position.z = -0.25;
  skillsTextF1.rotation.y = -Math.PI / 2;

  

  

  const  geometryTextOracle = new TextGeometry("Oracle Solution Services(India)", {
    font: font,
    size: 0.1,
    height: 0.0001,
    curveSegments: 5,
  });

   geometryTextOracle.center();
  const skillsTextOracle = new THREE.Mesh( geometryTextOracle, materialTextWorkExp);
  skillsTextOracle.position.x = 5;
  skillsTextOracle.position.y = 1;
  skillsTextOracle.position.z = 2.35;
  skillsTextOracle.rotation.y = -Math.PI / 2;

  const  geometryTextOracleDesig = new TextGeometry(`Staff Consultant
(Aug 2018 – July 2021)`, {
    font: font,
    size: 0.07,
    height: 0.0001,
    curveSegments: 5,
  });

  // geometryTextASMDesig.center();
  const skillsTextOracleDesig = new THREE.Mesh( geometryTextOracleDesig, materialTextWorkExp);
  skillsTextOracleDesig.position.x = 5;
  skillsTextOracleDesig.position.y = 0.8;
  skillsTextOracleDesig.position.z = 1.4;
  skillsTextOracleDesig.rotation.y = -Math.PI / 2;

  
  const  geometryTextVyapar = new TextGeometry("Simply Vyapar ", {
    font: font,
    size: 0.1,
    height: 0.0001,
    curveSegments: 5,
  });

   geometryTextVyapar.center();
  const skillsTextVyapar = new THREE.Mesh( geometryTextVyapar, materialTextWorkExp);
  skillsTextVyapar.position.x = 5;
  skillsTextVyapar.position.y = 1;
  skillsTextVyapar.position.z = 4.0;
  skillsTextVyapar.rotation.y = -Math.PI / 2;

  const  geometryTextVyaparDesig = new TextGeometry(`Senior Software Engineer
(July 2021 – Oct 2021)`, {
    font: font,
    size: 0.07,
    height: 0.0001,
    curveSegments: 5,
  });

  // geometryTextASMDesig.center();
  const skillsTextVyaparDesig = new THREE.Mesh( geometryTextVyaparDesig, materialTextWorkExp);
  skillsTextVyaparDesig.position.x = 5;
  skillsTextVyaparDesig.position.y = 0.8;
  skillsTextVyaparDesig.position.z = 3.55;
  skillsTextVyaparDesig.rotation.y = -Math.PI / 2;


  const  geometryTextMaximl = new TextGeometry("Maximl Labs", {
    font: font,
    size: 0.1,
    height: 0.0001,
    curveSegments: 5,
  });

   geometryTextMaximl.center();
  const skillsTextMaximl = new THREE.Mesh( geometryTextMaximl, materialTextWorkExp);
  skillsTextMaximl.position.x = 5;
  skillsTextMaximl.position.y = 1;
  skillsTextMaximl.position.z = 5.4;
  skillsTextMaximl.rotation.y = -Math.PI / 2;

  const  geometryTextMaximlDesig = new TextGeometry(`Senior Software Developer
(Aug 2018 – July 2021)`, {
      font: font,
      size: 0.07,
      height: 0.0001,
      curveSegments: 5,
    });
  
    // geometryTextASMDesig.center();
    const skillsTextMaximlDesig = new THREE.Mesh( geometryTextMaximlDesig, materialTextWorkExp);
    skillsTextMaximlDesig.position.x = 5;
    skillsTextMaximlDesig.position.y = 0.8;
    skillsTextMaximlDesig.position.z = 5.02;
    skillsTextMaximlDesig.rotation.y = -Math.PI / 2;
  
    

  experience.scene.add(skillsTextWorkExp,skillsTextASMDesig,skillsTextF1); //skillsTextASMDesig,skillsTextOracle,skillsTextOracleDesig,skillsTextVyapar,skillsTextVyaparDesig,skillsTextMaximl,skillsTextMaximlDesig

  //Resume
  const geometryTextResume = new TextGeometry(`Resumé`, {
    font: font,
    size: 0.1,
    height: 0.0001,
    curveSegments: 5,
  });

  geometryTextResume.center();
  // const materialTextResume = new THREE.MeshStandardMaterial({
  //   color: 0x959595,
  // });
  const skillsTextResume = new THREE.Mesh(
    geometryTextResume,
    materialTextWorkExp
  );
  skillsTextResume.position.x = -2.8;
  skillsTextResume.position.y = -0.35;
  skillsTextResume.position.z = -1.0;

  const geometryTextTakeLine = new TextGeometry(
    `Take one if you like (press R)`,
    {
      font: font,
      size: 0.06,
      height: 0.0001,
      curveSegments: 5,
    }
  );
  const skillsTextTakeLine = new THREE.Mesh(
    geometryTextTakeLine,
    materialTextWorkExp
  );
  skillsTextTakeLine.position.x = -3.35;
  skillsTextTakeLine.position.y = -0.55;
  skillsTextTakeLine.position.z = -1.0;

  experience.scene.add(skillsTextResume, skillsTextTakeLine);
});


