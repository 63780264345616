import * as THREE from 'three'
import Experience from '../Experience.js'



export default class Door
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('door')
        }

        // Resource
        this.resource = this.resources.items.doorModel

        this.setTextures()
        this.setModel()
    }

    setTextures()
    {
        this.textures = {}

    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(1.3, 1.3, 1.1)
        this.model.position.set(-4.97,-2,5.2)
        this.model.rotation.set(0,Math.PI/2,0)
        this.scene.add(this.model)


    }

    
}