import * as THREE from 'three'
import Experience from '../Experience.js'
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper.js";


export default class Monitor
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('monitor')
        }

        // Resource
        this.resource = this.resources.items.monitorModel

        this.setTextures()
        this.setModel()
        this.setAnimation()
    }

    setTextures()
    {
        this.textures = {}

        // this.textures.color = this.resources.items.tableTopTexture
        // // this.textures.color.encoding = THREE.sRGBEncoding
        // this.textures.color.repeat.set(2,2)
        // this.textures.color.wrapS = THREE.RepeatWrapping
        // this.textures.color.wrapT = THREE.RepeatWrapping

        // this.textures.normal = this.resources.items.tableTopNormalTexture
        // this.textures.normal.repeat.set(2, 2)
        // this.textures.normal.wrapS = THREE.RepeatWrapping
        // this.textures.normal.wrapT = THREE.RepeatWrapping


    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(0.15, 0.15, 0.15)
        this.model.position.set(-4.7,-0.98,1.05)

        this.scene.add(this.model)

        this.rectLightLeft = new THREE.RectAreaLight(0xffffff, 5, 1.4, 0.65);
        this.rectLightLeft.position.set(-4.61, -0.18, 1.05);
        this.rectLightLeft.rotation.set(0,Math.PI /2,0)
        this.scene.add(this.rectLightLeft);
        // this.scene.add(new RectAreaLightHelper(this.rectLightLeft))
        
        this.model.element = document.createElement('video')
        this.model.element.muted = true
        this.model.element.loop = true
        this.model.element.controls = true
        this.model.element.playsInline = true
        this.model.element.autoplay = true
        this.model.element.src = './ramayan.mp4'
        this.model.element.play()

        this.model.texture = new THREE.VideoTexture(this.model.element)
        this.model.texture.encoding = THREE.sRGBEncoding
        this.model.texture.flipY = false


        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                // child.castShadow = true
                if(child.name == "video"){
                    child.material =  new THREE.MeshBasicMaterial({
                        map: this.model.texture
                    })
                }else {
                    child.material = new THREE.MeshPhongMaterial({
                            color: child.material.color,
                        })
                }
            }
        })
    }

    setAnimation()
    {
        this.animation = {}
        
        
    }

    // update()
    // {
    //     this.animation.mixer.update(this.time.delta * 0.001)
    // }
}