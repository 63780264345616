import * as THREE from 'three'
import Experience from '../Experience.js'

export default class IronMan
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('ironMan')
        }

        // Resource
        this.resource = this.resources.items.ironManModel

        this.setModel()
        this.setAnimation()
    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(0.6, 0.4, 0.3)
        this.model.position.set(0,-1.65,6.5)
        this.scene.add(this.model)

        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                // child.castShadow = true
                child.material = new THREE.MeshStandardMaterial({
                    color: 0x131035
                })
            }
        })
    }

    setAnimation()
    {
        this.animation = {}
        
       
    }

    
}