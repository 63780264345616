import * as THREE from "three";
import Experience from "../Experience.js";
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper.js";
import EventEmitter from "../Utils/EventEmitter.js";

export default class Environment extends EventEmitter{
  constructor() {
    super()
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("environment");
    }

    this.setWindowLight();
    // this.setSkyLight();
    // this.setMoonLight();
    // this.setEnvironmentMap();
    this.setRainLight();
    // this.testRoomLight()
    // this.deskLight();

  }

  setWindowLight() {
    this.rectLight = new THREE.RectAreaLight(0xf5f5f5, 5, 3, 1.8);
    this.rectLight.position.set(0, 0, -1);
    this.rectLight.lookAt(0, 0.2, 5);
    this.scene.add(this.rectLight);
    // this.scene.add(new RectAreaLightHelper(this.rectLight))
  }

  setSkyLight() {
    this.skyLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 1);
    // this.skyLight.color.setHSL(0.6, 1, 0.6);
    this.skyLight.groundColor.setHSL(34, 3, 62);
    this.skyLight.position.set(10, 20, 0);
    this.scene.add(this.skyLight);
  }

  deskLight(){
    this.testRoomLight = new THREE.PointLight(0xEFC070, 20, 400, 2);
    this.testRoomLight.position.set(-4.5, 1.3, 2);
    this.scene.add(this.testRoomLight);
    const pointLightHelper = new THREE.PointLightHelper(this.testRoomLight, 0.2);
    this.scene.add(pointLightHelper); 
  }

  setEnvironmentMap() {
    this.environmentMap = {};
   
  }

  setRainLight() {

    this.ambient = new THREE.AmbientLight(0x555555);
    this.scene.add(this.ambient);

    this.directionalLight = new THREE.DirectionalLight(0xffeedd);
    this.directionalLight.position.set(0, 0, 1);
    // this.scene.add(this.directionalLight);

    // const DirectionalLightHelper = new THREE.DirectionalLightHelper(this.directionalLight, 1);
    // this.scene.add(DirectionalLightHelper)

    this.flash = new THREE.PointLight(0x062d89, 30, 400 ,1.7);
    this.flash.position.set(200,100,-50);
    this.scene.add(this.flash);

  }

  testRoomLight(){
    this.testRoomLight = new THREE.PointLight(0x555555, 50, 300, 2);
    this.testRoomLight.position.set(4, 0, 0);
    this.scene.add(this.testRoomLight);
    const pointLightHelper = new THREE.PointLightHelper(this.testRoomLight, 1);
    this.scene.add(pointLightHelper); 
  }


  updateRainFlash(){
    if(Math.random() > 0.93 || this.flash.power > 100) {
      if(this.flash.power < 100) 
        this.flash.position.set(
          Math.random()*400,
          100 + Math.random() *100,
          -300
        );
      this.flash.power = 50 + Math.random() * 2000;
    }

  }
}
