import * as THREE from 'three'
import Experience from '../Experience.js'



export default class Wall
{
    constructor(width,height,color,position,rotation)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        this.setGeometry(width,height)
        this.setMaterial(color)
        this.setMesh(position,rotation)
    }

    setGeometry(width,height)
    {
        this.geometry = new THREE.PlaneGeometry(width,height,1,1)
    }


    setMaterial(color)
    {
       
            this.material = new THREE.MeshStandardMaterial({
                // map: this.textures.color,
                // normalMap: this.textures.normal
                color: color,
                side: THREE.DoubleSide,
            })
        
       
    }

    setMesh(position,rotation)
    {
        this.mesh = new THREE.Mesh(this.geometry, this.material)

        //Position
        if(position){
            if(position.x){
                this.mesh.position.x = position.x
            }
            if(position.y){
                this.mesh.position.y = position.y
            }
            if(position.z){
                this.mesh.position.z = position.z
            }
        }
        

        //Rotation
        if(rotation){
            if(rotation.x){
                this.mesh.rotation.x = rotation.x
            }
            if(rotation.y){
                this.mesh.rotation.y = rotation.y
            }
            if(rotation.z){
                this.mesh.rotation.z = rotation.z
            }
        }
        
        
        // this.mesh.receiveShadow = true
        this.scene.add(this.mesh)
    }
}