import * as THREE from 'three'
import Experience from '../Experience.js'
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper.js";


export default class Table
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug


        // Resource
        this.resource = this.resources.items.tableModel

        this.setTextures()
        this.setModel()
        this.setAnimation()
    }

    setTextures()
    {
        this.textures = {}

        this.textures.color = this.resources.items.tableTopTexture
        // this.textures.color.encoding = THREE.sRGBEncoding
        this.textures.color.repeat.set(2,2)
        this.textures.color.wrapS = THREE.RepeatWrapping
        this.textures.color.wrapT = THREE.RepeatWrapping

      


    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(0.35, 0.35, 0.3)
        this.model.position.set(-4.5,-2,-0.52)
        this.scene.add(this.model)

        this.rectLightLeft = new THREE.RectAreaLight(0xff00ff, 30, 3.3, 0.09);
        this.rectLightLeft.position.set(-4.6, -1.062, 0.8);
        this.rectLightLeft.rotation.set(0,Math.PI /2,0)
        this.scene.add(this.rectLightLeft);
        // this.scene.add(new RectAreaLightHelper(this.rectLightLeft))

        this.rectLightFront = new THREE.RectAreaLight(0xff00ff, 30, 2.52, 0.09);
        this.rectLightFront.position.set(-3.7, -1.062, -0.6);
        // this.rectLightFront.rotation.set(0,Math.PI,0)
        // this.rectLight.lookAt(-4.5, -0.9, 1.5);
        this.scene.add(this.rectLightFront);
        // this.scene.add(new RectAreaLightHelper(this.rectLightFront))

        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                // child.castShadow = true
                if(child.name == "TableTop"){
                    child.material = new THREE.MeshStandardMaterial({
                        map: this.textures.color,
                        side: THREE.DoubleSide
                    })
                }
            }
        })
    }

    setAnimation()
    {
        this.animation = {}
        
       
    }

    
}