import * as THREE from 'three'
import Experience from '../Experience.js'



export default class Keyboard
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug


        // Resource
        this.resource = this.resources.items.keyboardModel


        this.setModel()
        this.setAnimation()
    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(0.25, 0.25, 0.25)
        this.model.position.set(-4.3,-1,1.0)
        // this.model.rotation.set(0,Math.PI/9,0)
        this.scene.add(this.model)

    }

    setAnimation()
    {
        this.animation = {}
    }

    
}