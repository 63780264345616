import * as THREE from 'three'
import Experience from '../Experience.js'



export default class Switch
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug


        // Resource
        this.resource = this.resources.items.switchModel


        this.setModel()
        this.setAnimation()
    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(0.23, 0.23, 0.23)
        this.model.position.set(-4.5,-1,2.1)
        this.model.rotation.set(0,Math.PI/9,0)
        this.scene.add(this.model)

        this.model.traverse((child) =>
        {
            
            if(child instanceof THREE.Mesh)
            {
                // console.log(child)
                // child.castShadow = true
                if(child.material.name == 'rightCon'){
                    child.material = new THREE.MeshPhongMaterial({
                            color: child.material.color,
                        })
                }else if(child.material.name == 'leftCon'){
                    child.material = new THREE.MeshPhongMaterial({
                            color: child.material.color,
                        })
                }else if(child.material.name == 'Material.001'){
                    child.material = new THREE.MeshPhongMaterial({
                            color: child.material.color,
                        })
                }else if(child.material.name == 'black'){
                    child.material = new THREE.MeshPhongMaterial({
                            color: child.material.color,
                        })
                }
            }
        })

    }

    setAnimation()
    {
        this.animation = {}
    }

    
}