import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls.js";

const cursor = {
    x: 0,
    y: 0
}
export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas

        this.mode = 'default' // default \ debug

        this.camRot = 0
        
        this.canvas.addEventListener('touchmove', (event) =>
        {
            
            cursor.x = event.touches[0].clientX / this.sizes.width - 0.5
            // console.log("cam",this.camRot)

            this.instance.rotation.y = this.camRot - cursor.x
            
        })
        this.canvas.addEventListener('touchend', (event) =>
        {
            this.camRot = this.instance.rotation.y
            cursor.x = 0
            
        })
        this.setInstance()
        this.setControls()
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(75, this.sizes.width / this.sizes.height, 0.1,
        2000)
        this.instance.position.z = 3;
        // this.instance.position.x = -3;


        this.instance.rotation.reorder('YXZ')

        this.scene.add(this.instance)
    }

    setControls()
    {
        // this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls = new PointerLockControls(this.instance, this.canvas)

        this.controls.enableDamping = true
        // this.controls.pointerSpeed = 0.5

        console.log(this.controls)

        
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update()
    {
      
    }
}