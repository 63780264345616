import * as THREE from 'three'
import Experience from '../Experience.js'
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper.js";


export default class TV
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        // Resource
        this.resource = this.resources.items.tvModel

        this.setTextures()
        this.setModel()
        this.setAnimation()
    }

    setTextures()
    {
        this.textures = {}

        // this.textures.color = this.resources.items.tableTopTexture
        // // this.textures.color.encoding = THREE.sRGBEncoding
        // this.textures.color.repeat.set(2,2)
        // this.textures.color.wrapS = THREE.RepeatWrapping
        // this.textures.color.wrapT = THREE.RepeatWrapping

        // this.textures.normal = this.resources.items.tableTopNormalTexture
        // this.textures.normal.repeat.set(2, 2)
        // this.textures.normal.wrapS = THREE.RepeatWrapping
        // this.textures.normal.wrapT = THREE.RepeatWrapping


    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(0.45, 0.45, 0.45)
        this.model.position.set(4.7,-0.5,3)
        this.model.rotation.set(0,-Math.PI /2,0)

        this.scene.add(this.model)

        this.rectLightLeft = new THREE.RectAreaLight(0xffffff, 5, 3, 1.5);
        this.rectLightLeft.position.set(4.61, 0.44, 3);
        this.rectLightLeft.rotation.set(0,-Math.PI /2,0)
        this.scene.add(this.rectLightLeft);
        // this.scene.add(new RectAreaLightHelper(this.rectLightLeft))
        

        this.model.texture = this.resources.items.tvTexture
        this.model.texture.encoding = THREE.sRGBEncoding
        this.model.texture.flipY = false


        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                // child.castShadow = true
                if(child.name == "screen"){
                    child.material =  new THREE.MeshBasicMaterial({
                        map: this.model.texture
                    })
                }else {
                    child.material = new THREE.MeshPhongMaterial({
                            color: child.material.color,
                        })
                }
            }
        })
    }

    setAnimation()
    {
        this.animation = {}
        
        
    }

    // update()
    // {
    //     this.animation.mixer.update(this.time.delta * 0.001)
    // }
}