import * as THREE from "three";
import Experience from "./Experience.js";
import normalizeWheel from "normalize-wheel";

let moveForward = false;
let moveBackward = false;
let moveLeft = false;
let moveRight = false;

let prevTime = performance.now();
const velocity = new THREE.Vector3();
const direction = new THREE.Vector3();

export default class Navigation {
  constructor() {
    this.experience = new Experience();
    this.targetElement = this.experience.canvas;
    this.camera = this.experience.camera;
    this.config = this.experience.config;
    this.time = this.experience.time;
    this.scene = this.experience.scene

    this.setView();
  }

  setView() {
    this.view = {};
    
    /**
     * Touch events
     */
    this.view.onTouchStart = (_event) => {
      _event.preventDefault();

      
    };

    this.view.onTouchMove = (_event) => {
      _event.preventDefault();

      // this.view.move(_event.touches[0].clientX, _event.touches[0].clientY)
    };

    this.view.onTouchEnd = (_event) => {
      _event.preventDefault();

      this.view.up();

      
    };

    

    /**
     * Context menu
     */
    this.view.onContextMenu = (_event) => {
      _event.preventDefault();
    };

    window.addEventListener("contextmenu", this.view.onContextMenu);

    /**
     * Wheel
     */
    this.view.onWheel = (_event) => {
      _event.preventDefault();

      
    };

    window.addEventListener("mousewheel", this.view.onWheel, {
      passive: false,
    });
    window.addEventListener("wheel", this.view.onWheel, { passive: false });
  }

  update() {
    const time = performance.now();
    

    prevTime = time;
  }
}
