import * as THREE from 'three'
import Experience from '../Experience.js'
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper.js";

export default class DropLight
{
    constructor(position,rotation,lightPosition,lightRotation,modelName,power)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        // // Debug
        // if(this.debug.active)
        // {
        //     this.debugFolder = this.debug.ui.addFolder('dropLight')
        // }

        // Resource
        this.resource = this.resources.items[modelName]

        this.setModel(position,rotation,lightPosition,lightRotation,power)
    }

    setModel(position,rotation,lightPosition,lightRotation,power)
    {
        this.model = this.resource.scene
        this.model.scale.set(0.10, 0.10, 0.10)
        this.model.position.set(position.x,position.y,position.z)
        // this.model.position.set(-4.2,0,-0.48)
        this.model.rotation.set(rotation.x,rotation.y,rotation.z)

        // this.model.rotation.set(0,Math.PI,0)
        this.rectDropLight = new THREE.RectAreaLight(0xFF9D21, 10, 0.75, 0.09);
        this.rectDropLight.position.set(lightPosition.x,lightPosition.y,lightPosition.z);
        // this.rectDropLight.lookAt(-3,0.5,-0.6);
        this.rectDropLight.rotation.set(lightRotation.x,lightRotation.y,lightRotation.z)
        this.rectDropLight.power = power
        this.scene.add(this.rectDropLight);
        // this.scene.add(new RectAreaLightHelper(this.rectDropLight))
        this.scene.add(this.model)

    }


}