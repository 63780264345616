import * as THREE from "three";
import Experience from "../Experience.js";
import lampFragment from "../Shaders/lampFragment.glsl"
import lampVertex from "../Shaders/lampVertex.glsl"


export default class Lamp {
  constructor(position, lightPosition) {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.debug = this.experience.debug;
    // this.lampTopTexture = this.resources.items.lampTopTexture

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("lamp");
    }

    // Resource
    this.resource = this.resources.items.lampModel;

    this.setModel(position, lightPosition);
    this.setAnimation();
  }

  setModel(position, lightPosition) {
    this.model = this.resource.scene;
    this.model.scale.set(0.28, 0.28, 0.28);
    this.model.position.set(position.x, position.y, position.z);

    this.model.rotation.set(0, Math.PI, 0);
    this.lampLight = new THREE.PointLight(0xFFF5A8, 10, 20, 2);
    this.lampLight.position.set(
      lightPosition.x,
      lightPosition.y,
      lightPosition.z
    );
    this.lampLight.rotateX(-Math.PI / 2);
    this.lampLight.power = 20;
    this.scene.add(this.lampLight);
    // this.scene.add(new THREE.PointLightHelper(this.lampLight, 0.5));
    this.scene.add(this.model);

    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        // child.castShadow = true;
        if (child.name == "Cylinder") {
          child.material = new THREE.ShaderMaterial({
            vertexShader: lampVertex,
            fragmentShader: lampFragment,
            transparent: true,
            side: THREE.DoubleSide,
            // uniforms: {
            //   uTexture: { value: this.lampTopTexture },
            // },
          });
        }
      }
    });
  }

  setAnimation() {
    this.animation = {};
  }

  // update()
  // {
  //     this.animation.mixer.update(this.time.delta * 0.001)
  // }
}
