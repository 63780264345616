import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Chair
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('chair')
        }

        // Resource
        this.resource = this.resources.items.chairModel

        this.setModel()
    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.position.set(-3.6,-2.01,1.8)
        this.model.scale.set(0.45,0.45,0.45)
        this.scene.add(this.model)

        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                    child.material = new THREE.MeshPhongMaterial({
                        color: child.material.color,
                    })
                
            }
        })
    }

}