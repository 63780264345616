import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Macbook
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug


        // Resource
        this.resource = this.resources.items.macbookModel
        this.standResource = this.resources.items.laptopStandModel

        this.setTextures()
        this.setModel()
        this.setAnimation()
    }

    setTextures()
    {
        this.textures = {}



    }

    setModel()
    {
        this.standModel = this.standResource.scene
        this.standModel.scale.set(0.17, 0.1, 0.16)
        this.standModel.position.set(-4.27,-0.9,-0.15)
        this.standModel.rotation.set(0,-(Math.PI/5),0)

        this.model = this.resource.scene
        this.model.scale.set(0.4, 0.4, 0.4)

        this.model.position.set(-4.27,-0.59,-0.2)
        this.model.rotation.set(0,-(Math.PI/5),-Math.PI/18)

        this.scene.add(this.model,this.standModel)

        this.model.element = document.createElement('video')
        this.model.element.muted = true
        this.model.element.loop = true
        this.model.element.controls = true
        this.model.element.playsInline = true
        this.model.element.autoplay = true
        this.model.element.src = './codeTexture.mp4'
        this.model.element.play()

        this.model.texture = new THREE.VideoTexture(this.model.element)
        this.model.texture.encoding = THREE.sRGBEncoding
        this.model.texture.flipY = false

        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                // child.castShadow = true
                if(child.name == "codeScreen"){
                    child.material =  new THREE.MeshBasicMaterial({
                        map: this.model.texture
                    })
                }else {
                    child.material = new THREE.MeshPhongMaterial({
                            color: child.material.color,
                        })
                }
            }
        })

    }

    setAnimation()
    {
        this.animation = {}
        
        
    }

}