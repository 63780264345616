import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import EventEmitter from './EventEmitter.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";

export default class Resources extends EventEmitter
{
    constructor(sources,loadingManager)
    {
        super()

        this.sources = sources

        this.items = {}
        this.toLoad = this.sources.length
        this.loaded = 0


        this.setLoaders(loadingManager)
        this.startLoading()
    }

    setLoaders(loadingManager)
    {
        this.loaders = {}
        this.loaders.gltfLoader = new GLTFLoader(loadingManager)
        this.loaders.textureLoader = new THREE.TextureLoader(loadingManager)
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)
        this.loaders.dracoLoader = new DRACOLoader()
        this.loaders.dracoLoader.setDecoderPath('draco/')
        this.loaders.dracoLoader.setDecoderConfig({ type: 'js' })
        this.loaders.gltfLoader.setDRACOLoader(this.loaders.dracoLoader)
        this.loaders.fontLoader = new FontLoader(loadingManager)
    }

    startLoading()
    {
        // Load each source
        for(const source of this.sources)
        {
            if(source.type === 'gltfModel')
            {
                this.loaders.gltfLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if(source.type === 'texture')
            {
                this.loaders.textureLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if(source.type === 'cubeTexture')
            {
                this.loaders.cubeTextureLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }else if(source.type === 'draco'){
                this.loaders.dracoLoader.load(source.path, (file) =>
                {
                    this.sourceLoaded(source, file)

                    DRACOLoader.releaseDecoderModule()
                })
            }else if(source.type === 'font'){
                this.loaders.fontLoader.load(source.path, (file)=> {
                    this.sourceLoaded(source, file)
                })
            }
        }
    }

    sourceLoaded(source, file)
    {
        this.items[source.name] = file

        this.loaded++

        if(this.loaded === this.toLoad)
        {
            this.trigger('ready')
        }
    }
}