import Experience from '../Experience.js'
import Chair from './chair.js'
import Couch from './couch.js'
import DropLight from './dropLight.js'
import Environment from './Environment.js'
import Floor from './Floor.js'
import FrontWall from './frontWall.js'
import IronMan from './IronMan.js'
import Lamp from './lamp.js'
import Macbook from './macbook.js'
import Monitor from './Monitor.js'
import PictureFrame from './pictureFrame.js'
import PlantM from './plantM.js'
import Rain from './Rain.js'
import Roof from './Roof.js'
import Table from './Table.js'
import BookShelf from './bookShelf.js'
import Wall from './Wall.js'
import Window from './Window.js'
import HangLight from './hangLight.js'
import Door from './door.js'
import Resume from './resume.js'
import Switch from './switch.js'
import Keyboard from './keyboard.js'
import TV from './tv.js'



export default class Room
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            this.rain = new Rain()
            this.floor = new Floor()
            this.table = new Table()
            this.bookShelf = new BookShelf()
            this.door = new Door()
            this.frontWall = new FrontWall()
            this.backWall = new Wall(10, 4,0x131035,{z:7})
            this.RightWall = new Wall(8, 4,0x131035,{x:5,z:3},{y: Math.PI /2})
            this.LeftWall = new Wall(8, 4,0x131035,{x:-5,z:3},{y: -(Math.PI / 2)})

            this.roof = new Roof()
            this.window = new Window()
            this.chair = new Chair()
            this.ironMan = new IronMan()
            this.plantM = new PlantM()
            this.bTech = new PictureFrame({x:-3.15,y:0.1,z:-0.95},{x:2,y: 2.1,z: 2},this.resources.items.btechDegreeTexture,0x32140C,this.resources.items.pictureFrameModel)
            this.jaipur = new PictureFrame({x:2.8,y:-0.7,z:-0.95},{x: 2,y: 4,z: 4.5},this.resources.items.rajasthanTexture,0x32140C,this.resources.items.pictureFrame1Model)

            this.dropLightFrontWall = new DropLight({x:-3,y:1,z:-0.75},{x:0,y:Math.PI,z:0},{x:-3,y:1.1,z:-0.75},{x:-Math.PI/2,y:0,z:0},'dropLightModel',20)
            // this.dropLightFrontWall1 = new DropLight({x:3.2,y:1,z:-0.75},{x:0,y:Math.PI,z:0},{x:3.2,y:1.1,z:-0.75},{x:-Math.PI/2,y:0,z:0},'dropLightFront1Model',25)
            // this.dropLightRightWall1 = new DropLight({x:4.8,y:1.5,z:0.5},{x:0,y:Math.PI/2,z:0},{x:4.8,y:1.6,z:0.5},{x:Math.PI/2,y:-Math.PI,z:-Math.PI/2},'dropLightRight1Model',25)
            this.dropLightRightWall2 = new DropLight({x:4.8,y:1.2,z:0.5},{x:0,y:Math.PI/2,z:0},{x:4.8,y:1.3,z:0.5},{x:Math.PI/2,y:-Math.PI,z:-Math.PI/2},'dropLightRight2Model',25)
            // this.dropLightRightWall3 = new DropLight({x:4.8,y:1.4,z:4.5},{x:0,y:Math.PI/2,z:0},{x:4.8,y:1.5,z:4.5},{x:Math.PI/2,y:-Math.PI,z:-Math.PI/2},'dropLightRight3Model',25)
            // this.dropLightRightWall4 = new DropLight({x:4.8,y:1.5,z:5.5},{x:0,y:Math.PI/2,z:0},{x:4.8,y:1.6,z:5.5},{x:Math.PI/2,y:-Math.PI,z:-Math.PI/2},'dropLightRight4Model',25)
            

            this.hangLight1 = new HangLight({x:-3.2,y:1.2,z:1},{x:-3.2,y:1.2,z:1},'hangLight1Model',200)
            this.hangLight2 = new HangLight({x:-3.4,y:1.2,z:4.2},{x:-3.4,y:1.2,z:4.2},'hangLight2Model',110)
            this.hangLight3 = new HangLight({x:3.4,y:1.2,z:0.8},{x:3.4,y:1.2,z:0.8},'hangLight3Model',110)
            this.hangLight4 = new HangLight({x:3.4,y:1.2,z:4.4},{x:3.4,y:1.2,z:4.4},'hangLight4Model',120)

            this.resume = new Resume()
            this.lamp = new Lamp({x:-4.5,y:-2.04,z:6.4},{x:-4.5,y:0.1,z:6.4})
            this.monitor = new Monitor()
            this.macbook = new Macbook()
            this.switch = new Switch()
            // this.couch = new Couch()
            this.keyBoard = new Keyboard()
            this.tv = new TV()
            this.environment = new Environment()
        })
    }

    update()
    {
        if(this.rain){
            this.rain.update()
        }
        if(this.environment){
            this.environment.updateRainFlash()
        }
        
    }
}