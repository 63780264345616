import * as THREE from 'three'
import Experience from '../Experience.js'



export default class Resume
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('resume')
        }

        // Resource
        this.resource = this.resources.items.resumeModel

        this.setTextures()
        this.setModel()
        this.setAnimation()
    }

    setTextures()
    {
        this.textures = {}

    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(0.2, 0.2, 0.15)
        this.model.position.set(-2.8,-0.89,-0.42)
        // this.model.rotation.set(0,Math.PI/2,0)
        this.scene.add(this.model)


        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                // child.castShadow = true
                if(child.material.name == 'Material.001'){
                    child.material = new THREE.MeshPhongMaterial({
                            color: child.material.color,
                        })
                }

            }
        })
    }

    setAnimation()
    {
        this.animation = {}
        
       
    }

    // update()
    // {
    //     this.animation.mixer.update(this.time.delta * 0.001)
    // }
}