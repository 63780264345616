import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Floor
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        this.setGeometry()
        this.setMaterial()
        this.setMesh()
    }

    setGeometry()
    {
        this.geometry = new THREE.PlaneGeometry(10,8)
    }

    

    setMaterial()
    {
        this.material = new THREE.MeshStandardMaterial({
            // map: this.textures.color,
            // normalMap: this.textures.normal
            color: 0x131035,
            side: THREE.DoubleSide,
        })
    }

    setMesh()
    {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.position.z = 3;
        this.mesh.position.y = -2;
        this.mesh.rotation.x = Math.PI / 2;
        // this.mesh.receiveShadow = true
        this.scene.add(this.mesh)
    }
}