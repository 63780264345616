import * as THREE from 'three'
import Experience from '../Experience.js'



export default class BookShelf
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('bookShelf')
        }

        // Resource
        this.resource = this.resources.items.bookShelfModel

        this.setTextures()
        this.setModel()
        this.setAnimation()
    }

    setTextures()
    {
        this.textures = {}

    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(0.34, 0.34, 0.34)
        this.model.position.set(-4.6,-1.9,3.2)
        this.model.rotation.set(0,Math.PI/2,0)
        this.scene.add(this.model)


        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                if(child.material.name == 'book' ||
                child.material.name == 'Material.001'||
                child.material.name == 'Material.002'||
                child.material.name == 'Material.003'||
                child.material.name == 'Material.004'||
                child.material.name == 'Book0_material' || 
                child.material.name == 'Book0_side_material' || 
                child.material.name == 'Book1_material' || 
                child.material.name == 'Book1_side_material' ||
                child.material.name == 'Book2_material' || 
                child.material.name == 'Book2_side_material' || 
                child.material.name == 'Book3_material' ||
                child.material.name == 'Book3_side_material' ||
                child.material.name == 'Book4_material' ||
                child.material.name == 'Book4_side_material' ){
                    child.material = new THREE.MeshPhongMaterial({
                            color: child.material.color,
                        })
                }
            }
        })
    }

    setAnimation()
    {
        this.animation = {}
    }

}